import { ProfileType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/UserContext';
import { MobileNavContainer, MobileNavItem, MobileNavText, DesktopNavContainer, DesktopNavItem, DesktopNavLink } from './Navigation.styles';

export const MobileNav = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const pathName = router.asPath;
	const { user } = useContext(UserContext);

	const navLinks = [
		{
			url: '/',
			text: t('Navigation.Frontpage'),
		},
		{
			url: '/live',
			text: t('Navigation.Live'),
		},
		{
			url: '/genre/stod2plus',
			text: t('Navigation.Stod2plus'),
		},
		{
			url: '/genre/sports',
			text: t('Navigation.Sports'),
		},
		{
			url: '/genre/children',
			text: t('Navigation.Children'),
			childFriendly: true,
		},
		{
			url: '/genre/rent',
			text: t('Navigation.Rent'),
		},
		{
			url: '/genre/catchup',
			text: t('Navigation.Frelsi'),
			childFriendly: true,
		},
		// {
		//	url: '/genre/siminn-premium',
		//	text: t('Navigation.Premium'),
		//	childFriendly: true,
		// },
		{
			url: '/my-list',
			text: t('Navigation.MyList'),
			childFriendly: true,
		},
	];
	return (
		<MobileNavContainer>
			<Link key={'search'} href={'/search'}>
				<MobileNavItem isSelected={pathName === '/search'}>
					<MobileNavText>{t('Navigation.Search')}</MobileNavText>
				</MobileNavItem>
			</Link>
			{navLinks
				.filter((l) => {
					return (user?.selectedProfile?.type === ProfileType.KIDS && l.childFriendly) || user?.selectedProfile?.type !== ProfileType.KIDS;
				})
				.map((link) => {
					return (
						<Link key={link.url} href={link.url}>
							<MobileNavItem isSelected={pathName === link.url}>
								<MobileNavText>{link.text}</MobileNavText>
							</MobileNavItem>
						</Link>
					);
				})}
		</MobileNavContainer>
	);
};

export default function Navigation() {
	const { t } = useTranslation();
	const router = useRouter();
	const pathName = router.asPath;
	const { user } = useContext(UserContext);

	const navLinks = [
		{
			url: '/live',
			text: t('Navigation.Live'),
		},
		{
			url: '/genre/stod2plus',
			text: t('Navigation.Stod2plus'),
		},
		{
			url: '/genre/sports',
			text: t('Navigation.Sports'),
		},
		{
			url: '/genre/children',
			text: t('Navigation.Children'),
			childFriendly: true,
		},
		{
			url: '/genre/rent',
			text: t('Navigation.Rent'),
		},
		{
			url: '/genre/catchup',
			text: t('Navigation.Frelsi'),
			childFriendly: true,
		},
		// {
		//	url: '/genre/siminn-premium',
		//	text: t('Navigation.Premium'),
		//	childFriendly: true,
		// },
	];
	return (
		<DesktopNavContainer>
			{navLinks
				.filter((l) => {
					return (user?.selectedProfile?.type === ProfileType.KIDS && l.childFriendly) || user?.selectedProfile?.type !== ProfileType.KIDS;
				})
				.map((link) => {
					return (
						<Link key={link.url} href={link.url}>
							<DesktopNavItem isSelected={pathName === link.url}>
								<DesktopNavLink>{link.text}</DesktopNavLink>
							</DesktopNavItem>
						</Link>
					);
				})}
		</DesktopNavContainer>
	);
}
